@import '../abstracts/variables';

@each $color-name, $color-value in $theme-colors {
  @if $color-name != 'palette_white' {
    .btn-#{$color-name} {
      --btn-color: var(--palette_white);
      --btn-hover-color: var(--palette_white);
      --btn-active-color: var(--palette_white);
      --btn-disabled-color: rgba(255, 255, 255, 0.4);
      --btn-hover-border-color: transparent;
      --btn-hover-bg: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.2) 0%,
          rgba(255, 255, 255, 0.2) 100%
        ),
        var(--#{$color-name});

      &:hover {
        background: var(--btn-hover-bg);
      }
    }

    .btn-outline-#{$color-name} {
      --btn-hover-bg: #{rgba($color-value, 0.4)};
      --btn-hover-color: #{$color-value};
      //--btn-active-bg: #{rgba($color-value, 0.2)};
      //--btn-active-color: #{$color-value};
      --btn-active-color: #{darken($color-value, 15%)};
    }
  }
}

@import '../abstracts/variables';

$text-background-color: #252f3d;
$component-background-color: var(--White, #fff);
$margin-arrow-side: 10px !important;

// Base class
.tooltip {
  // scss-docs-start tooltip-css-vars
  --#{$prefix}tooltip-zindex: #{$zindex-tooltip};
  --#{$prefix}tooltip-max-width: #{$tooltip-max-width};
  --#{$prefix}tooltip-padding-x: #{$tooltip-padding-x};
  --#{$prefix}tooltip-padding-y: #{$tooltip-padding-y};
  --#{$prefix}tooltip-margin: #{$tooltip-margin};
  @include rfs($tooltip-font-size, --#{$prefix}tooltip-font-size);
  --#{$prefix}tooltip-color: #{$tooltip-color};
  --#{$prefix}tooltip-bg: #{$tooltip-bg};
  --#{$prefix}tooltip-border-radius: #{$tooltip-border-radius};
  --#{$prefix}tooltip-opacity: #{$tooltip-opacity};
  --#{$prefix}tooltip-arrow-width: #{$tooltip-arrow-width};
  --#{$prefix}tooltip-arrow-height: #{$tooltip-arrow-height};
  // scss-docs-end tooltip-css-vars

  z-index: var(--#{$prefix}tooltip-zindex);
  display: block;
  margin: var(--#{$prefix}tooltip-margin);
  @include deprecate('`$tooltip-margin`', 'v5', 'v5.x', true);
  // Our parent element can be arbitrary since tooltips are by default inserted as a sibling of their target element.
  // So reset our font and text properties to avoid inheriting weird values.
  @include reset-text();
  @include font-size(var(--#{$prefix}tooltip-font-size));
  // Allow breaking very long words so they don't overflow the tooltip's bounds
  word-wrap: break-word;
  opacity: 0;

  &.show {
    opacity: var(--#{$prefix}tooltip-opacity);
  }

  .tooltip-arrow {
    display: block;
    width: var(--#{$prefix}tooltip-arrow-width);
    height: var(--#{$prefix}tooltip-arrow-height);

    &::before {
      position: absolute;
      content: '';
      border-color: transparent;
      border-style: solid;
    }
  }

  &.component {
    opacity: 1;

    .tooltip-inner {
      padding: 18px;
      border-radius: 8px;
      background: $component-background-color;
      max-width: none;
    }

    &.bs-tooltip-top .tooltip-arrow::before {
      border-top-color: $component-background-color;
    }

    &.bs-tooltip-end .tooltip-arrow::before {
      border-right-color: $component-background-color;
    }

    &.bs-tooltip-bottom .tooltip-arrow::before {
      border-bottom-color: $component-background-color;
    }

    &.bs-tooltip-start .tooltip-arrow::before {
      border-left-color: $component-background-color;
    }
  }
}

.bs-tooltip-top {
  margin-bottom: $margin-arrow-side;

  .tooltip-arrow {
    bottom: calc(
      -1 * var(--#{$prefix}tooltip-arrow-height)
    ); // stylelint-disable-line function-disallowed-list

    &::before {
      top: -1px;
      border-width: var(--#{$prefix}tooltip-arrow-height)
        calc(var(--#{$prefix}tooltip-arrow-width) * 0.5) 0; // stylelint-disable-line function-disallowed-list
      border-top-color: $text-background-color;
    }
  }
}

/* rtl:begin:ignore */
.bs-tooltip-end {
  margin-left: $margin-arrow-side;

  .tooltip-arrow {
    left: calc(
      -1 * var(--#{$prefix}tooltip-arrow-height)
    ); // stylelint-disable-line function-disallowed-list
    width: var(--#{$prefix}tooltip-arrow-height);
    height: var(--#{$prefix}tooltip-arrow-width);

    &::before {
      right: -1px;
      border-width: calc(var(--#{$prefix}tooltip-arrow-width) * 0.5)
        var(--#{$prefix}tooltip-arrow-height) calc(var(--#{$prefix}tooltip-arrow-width) * 0.5) 0; // stylelint-disable-line function-disallowed-list
      border-right-color: $text-background-color;
    }
  }
}

/* rtl:end:ignore */

.bs-tooltip-bottom {
  margin-top: $margin-arrow-side;

  .tooltip-arrow {
    top: calc(
      -1 * var(--#{$prefix}tooltip-arrow-height)
    ); // stylelint-disable-line function-disallowed-list

    &::before {
      bottom: -1px;
      border-width: 0 calc(var(--#{$prefix}tooltip-arrow-width) * 0.5)
        var(--#{$prefix}tooltip-arrow-height); // stylelint-disable-line function-disallowed-list
      border-bottom-color: $text-background-color;
    }
  }
}

/* rtl:begin:ignore */
.bs-tooltip-start {
  margin-right: $margin-arrow-side;

  .tooltip-arrow {
    right: calc(
      -1 * var(--#{$prefix}tooltip-arrow-height)
    ); // stylelint-disable-line function-disallowed-list
    width: var(--#{$prefix}tooltip-arrow-height);
    height: var(--#{$prefix}tooltip-arrow-width);

    &::before {
      left: -1px;
      border-width: calc(var(--#{$prefix}tooltip-arrow-width) * 0.5) 0
        calc(var(--#{$prefix}tooltip-arrow-width) * 0.5) var(--#{$prefix}tooltip-arrow-height); // stylelint-disable-line function-disallowed-list
      border-left-color: $text-background-color;
    }
  }
}

/* rtl:end:ignore */

.bs-tooltip-auto {
  &[data-popper-placement^='top'] {
    @extend .bs-tooltip-top;
  }
  &[data-popper-placement^='right'] {
    @extend .bs-tooltip-end;
  }
  &[data-popper-placement^='bottom'] {
    @extend .bs-tooltip-bottom;
  }
  &[data-popper-placement^='left'] {
    @extend .bs-tooltip-start;
  }
}

// Wrapper for the tooltip content
.tooltip-inner {
  max-width: var(--#{$prefix}tooltip-max-width);
  padding: 8px 14px;
  color: var(--#{$prefix}tooltip-color);
  text-align: center;
  border-radius: 3px;
  background: $text-background-color;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.08);
}

@import 'libs/shared/src/theme/base.scss';
@import 'libs/shared/src/theme/base/typography';

@mixin ellipsis() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.page {
  &-content {
    padding: 24px 12px 24px 24px;
  }

  &-title {
    @include page-title();
    @include ellipsis();
    color: var(--palette_black);
    text-transform: uppercase;
    max-width: 45vw;
  }
}

.pointer {
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}

::-webkit-scrollbar-track {
  border: 1px solid #e6e6e6;
  background: #f0f0f0;
}

::-webkit-scrollbar-track-piece:vertical:start {
  margin-top: 5px;
}

::-webkit-scrollbar-track-piece:vertical:end {
  margin-bottom: 5px;
}

::-webkit-scrollbar-track-piece:horizontal:start {
  margin-left: 5px;
}

::-webkit-scrollbar-track-piece:horizontal:end {
  margin-right: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border: 3px solid transparent;
  border-radius: 8px;
  background-clip: content-box;
}

::-webkit-scrollbar-corner {
  border: 1px solid #e6e6e6;
  background: #e6e6e6;
}

.see-more-tooltip {
  display: block;
  position: absolute;
  white-space: pre-wrap;
  border: 1px solid #dee7ee;
  color: var(--text_body);
  background-color: white;
  padding: 10px 16px 10px 16px;
  border-radius: 3px;
  font-size: 14px;
  line-height: 17px;
  box-shadow: 0px 4px 14px 0px #00000014;
  z-index: 10000;
}

.modal-form {
  display: flex !important;
  justify-content: center;
  align-items: center;

  .modal-dialog {
    max-width: 90%;
    width: fit-content;
  }

  .modal-content {
    overflow: hidden;
  }
}

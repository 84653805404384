// Multi-Line Truncation

$line-clamp-map: (2, 3);

@mixin truncate-line($line-clamp) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $line-clamp;
  overflow: hidden;
}

@each $line-clamp in $line-clamp-map {
  .truncate-line-#{$line-clamp} {
    @include truncate-line($line-clamp);
  }
}

// CSS variables prefix
$prefix: '' !default;

:root {
  --btn-disabled-color: rgba(255, 255, 255, 0.4);
  --filled_text: #727272;
  --text_body: #212529;
  --input-background: #f8f8f8;
  --input-border: rgba(206, 212, 218, 0.35);
}

// Palette
$theme-colors: (
  palette_primary: #2aaade,
  palette_additional: #1a4797,
  palette_secondary: #02164e,
  palette_success: #28a745,
  palette_danger: #f04e23,
  palette_warning: #faa819,
  palette_info: #708090,
  palette_disabled: #939393,
  palette_dark: #55575c,
  palette_grey: #f2f2f2,
  palette_black: #000,
  palette_white: #fff,
  palette_border: #ced4da,
);

$quick-status: (
  divider: #fff,
  background: #02164e,
);

// Override Bootstrap's default font family variables
$font-family-sans-serif: 'Montserrat', sans-serif !default;
$font-family-monospace: 'Roboto Mono', monospace !default;

// Side Navigation
$side-nav-width-desktop: 228px;

// Buttons
$btn-padding-x: 1.5rem;
$btn-padding-y: 0.4375rem;
$btn-font-size: 0.875rem;
$btn-font-weight: 600;
$btn-border-radius: 0.5rem;
$btn-line-height: 1.5rem;
$btn-disabled-opacity: 0.4;

// Form Elements
$form-element-disabled-opacity: 0.5;

// Pagination
$pagination-padding-y: 0;
$pagination-padding-x: 0;
$pagination-color: var(--gray-900);
$pagination-border-width: 1px;
$pagination-border-color: var(--gray-200);
$pagination-border-radius: 0.25rem;
$pagination-bg: var(--palette_white);
$pagination-focus-box-shadow: none;
$pagination-focus-color: $pagination-color;
$pagination-focus-bg: #2aaade33;
$pagination-hover-bg: $pagination-focus-bg;
$pagination-hover-border-color: $pagination-border-color;
$pagination-hover-color: $pagination-color;
$pagination-active-color: var(--palette_white);
$pagination-active-bg: var(--palette_primary);
$pagination-active-border-color: var(--palette_primary);
$pagination-disabled-color: $pagination-color;
$pagination-disabled-bg: $pagination-bg;
$pagination-disabled-border-color: $pagination-border-color;

// Breadcrumbs
$breadcrumbs-item-color: #36353966;

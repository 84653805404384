.bg-success {
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  background: #e9f6ec;
  border-left: 3px solid var(--palette_success);
}

.bg-danger {
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  background: #fcdcd3;
  border-left: 3px solid var(--palette_danger);
}

.bg-primary {
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  background: #d4eef8;
  border-left: 3px solid var(--palette_primary);
}

.bg-warning {
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  background: #feeed1;
  border-left: 3px solid var(--palette_warning);
}

ngb-pagination {
  .pagination {
    margin: 0;

    .page-link {
      width: 2rem;
      height: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;

      @include datatable-body-text();

      .pagination-ellipsis {
        font-family: var(--font-sans-serif);
      }
    }
  }
}

// General typography mixin
@mixin typography($font-family, $font-weight, $font-size, $line-height) {
  font-family: $font-family;
  font-weight: $font-weight;
  font-size: $font-size;
  line-height: $line-height;
}

@mixin datatable-header-text {
  @include typography(var(--font-monospace), 700, 0.875rem, 1rem);
}

@mixin datatable-body-text {
  @include typography(var(--font-monospace), 400, 0.875rem, 1rem);
}

@mixin input-text {
  @include typography(var(--font-sans-serif), 400, 0.875rem, 1.5rem);
}

@mixin page-title {
  @include typography(var(--font-sans-serif), 600, 1.5rem, 2.5rem);
}

@mixin breadcrumbs {
  @include typography(var(--font-sans-serif), 600, 0.875rem, 2.5rem);
}

@mixin form-element-label {
  @include typography(var(--font-sans-serif), 500, 0.875rem, 1.25rem);
}

@mixin form-element-error-message {
  @include typography(var(--font-sans-serif), 500, 0.875rem, 1.25rem);
}

@mixin form-element-info-message {
  @include typography(var(--font-sans-serif), 500, 0.875rem, 1.25rem);
}

@mixin menu-navigation-item {
  @include typography(var(--font-sans-serif), 400, 1rem, 1.5rem);
}

@mixin user-profile-name {
  @include typography(var(--font-sans-serif), 600, 0.75rem, 1.5rem);
}

@mixin info-value {
  @include typography(var(--font-sans-serif), 500, 1rem, 1.5rem);
}

@mixin popup-title {
  @include typography(var(--font-sans-serif), 600, 1.25rem, 1.5rem);
}

@mixin popup-text {
  @include typography(var(--font-sans-serif), 400, 0.875rem, 1.75rem);
}

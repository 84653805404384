@import '@swimlane/ngx-datatable';

$datatable-text-color: #212529;
$datatable-header-bg: #f2f2f2;
$datatable-even-row-bg: #fafafa;
$datatable-odd-row-bg: var(--palette_white);
$datatable-row-hover-bg: #2aaade12;
$datatable-cell-padding-x: 1rem;

ngx-datatable.ngx-datatable.drb {
  box-sizing: content-box;

  .datatable-header {
    background-color: $datatable-header-bg;
    margin-bottom: 0.5rem;

    .datatable-header-inner,
    .datatable-row-center,
    .datatable-header-cell {
      height: 100% !important;
    }

    .datatable-header-cell {
      color: $datatable-text-color;

      .resize-handle {
        display: none !important;
      }

      .datatable-header-cell-template-wrap {
        display: flex;
        align-items: center;

        .datatable-header-cell-wrapper {
          @include text-truncate();

          .datatable-header-cell-label {
            @include datatable-header-text();
          }
        }

        .sort-btn {
          padding-left: 0.625rem;

          &.datatable-icon-sort-unset::before,
          &.datatable-icon-up::before,
          &.datatable-icon-down::before {
            display: flex;
            line-height: 0;
          }

          &.datatable-icon-sort-unset::before {
            content: url('/assets/shared/svg-icons/sort.svg');
          }

          &.datatable-icon-up::before {
            content: url('/assets/shared/svg-icons/sort-asc.svg');
          }

          &.datatable-icon-down::before {
            content: url('/assets/shared/svg-icons/sort-desc.svg');
          }
        }
      }
    }
  }

  .datatable-body {
    .empty-row {
      height: 4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $datatable-text-color;
      background-color: $datatable-even-row-bg;

      @include datatable-body-text();
    }

    .datatable-scroll {
      width: 100% !important;
    }

    .datatable-row-wrapper:not(:last-child) {
      margin-bottom: 0.25rem;
    }

    .datatable-body-row {
      transition: background-color 0.15s ease-in-out;

      &:hover {
        background-color: $datatable-row-hover-bg !important;
      }

      &.datatable-row-even {
        background-color: $datatable-even-row-bg;
      }

      &.datatable-row-odd {
        background-color: $datatable-odd-row-bg;
      }

      .datatable-body-cell {
        color: $datatable-text-color;

        .datatable-body-cell-label {
          display: flex;
          align-items: center;
          white-space: normal;
          height: 100%;

          span {
            @include truncate-line(2);
            @include datatable-body-text();
          }
        }
      }
    }
  }

  .datatable-header,
  .datatable-body .empty-row,
  .datatable-body .datatable-body-row {
    border-radius: 0.75rem;
  }

  .datatable-body .empty-row,
  .datatable-body .datatable-body-row {
    box-shadow: 0px 4px 8px 4px rgba(0, 0, 0, 0.05);
  }

  .datatable-header .datatable-header-cell,
  .datatable-body .datatable-body-cell {
    padding: 0 $datatable-cell-padding-x;
  }
}

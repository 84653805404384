@import 'abstracts/variables';

// Core Bootstrap Functions & Variables
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/variables-dark';
@import 'bootstrap/scss/maps';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/root';

// Breakpoint overrides
@import 'abstracts/breakpoints';

// Bootstrap utility classes
@import 'bootstrap/scss/utilities';

// Modify utility classes
@import 'abstracts/utilities';

// Bootstrap Essentials
@import 'bootstrap/scss/reboot'; // Normalize and element reset
@import 'bootstrap/scss/type'; // Typography settings
@import 'bootstrap/scss/images'; // Responsive image classes
@import 'bootstrap/scss/containers'; // Bootstrap container styles
@import 'bootstrap/scss/grid'; // Bootstrap grid system
@import 'bootstrap/scss/helpers'; // Helper classes
@import 'bootstrap/scss/utilities/api'; // Utilities API for extending Bootstrap

// Bootstrap Components (comment out what you don't need)
// @import 'bootstrap/scss/accordion'; // Accordion component
// @import 'bootstrap/scss/alert'; // Alert component for feedback messages
// @import 'bootstrap/scss/badge'; // Small count and labeling component
// @import 'bootstrap/scss/breadcrumb'; // Navigation breadcrumb
@import 'bootstrap/scss/buttons'; // Button styles
@import 'bootstrap/scss/button-group'; // Grouping buttons together
@import 'bootstrap/scss/card'; // Card component for content grouping
// @import 'bootstrap/scss/carousel'; // Slideshow component
// @import 'bootstrap/scss/close'; // Close icon button
@import 'bootstrap/scss/dropdown'; // Dropdown component
@import 'bootstrap/scss/forms'; // Form elements and layout
// @import 'bootstrap/scss/list-group'; // List items component
@import 'bootstrap/scss/modal'; // Modal dialog component
// @import 'bootstrap/scss/nav'; // Navigation tabs, pills, and bars
// @import 'bootstrap/scss/navbar'; // Top-level navigation wrapper
@import 'bootstrap/scss/pagination'; // Pagination component
// @import 'bootstrap/scss/popover'; // Popover component
// @import 'bootstrap/scss/progress'; // Progress bars
// @import 'bootstrap/scss/spinners'; // Spinners for loading indication
// @import 'bootstrap/scss/toasts'; // Push notifications
// @import 'bootstrap/scss/tooltip'; // Tooltips for additional info

// Custom SCSS
@import 'base/fonts';
@import 'base/truncate';
@import 'base/typography';
@import 'components/buttons';
@import 'components/tooltip';
@import 'components/card';
@import 'components/datatable';
@import 'components/pagination';
@import 'components/notification';
@import 'components/tooltip';

body {
  height: 100vh;
}

p {
  margin-block-start: unset;
  margin-block-end: unset;
}

.text-danger {
  color: var(--palette_danger);
}

.text-success {
  color: var(--palette_success);
}

.text-primary {
  color: var(--palette_primary);
}

.card {
  border-radius: 0.75rem;
  border-width: 0;
  padding: 1.5rem;
  position: relative;
  gap: 1.5rem;
  background: var(--White, #fff);
  box-shadow:
    0px 4px 8px 4px rgba(0, 0, 0, 0.05),
    0px 15px 24px 0px rgba(0, 0, 0, 0.03);
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  &.no-shadow {
    box-shadow: unset;
  }

  &.disabled {
    &:before {
      content: '';
      border-radius: 0.75rem;
      background-color: var(--palette_disabled);
      position: absolute;
      top: 0;
      right: 0;
      opacity: 0.3;
      z-index: 3;
      bottom: 0;
      left: 0;
    }
  }
}
